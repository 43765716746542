import React, { PropsWithChildren, useEffect, useState } from 'react'
import useUserStore from '../../store/useUserStore'
import classNames from 'classnames'
import { UserType } from '../../utils/common'
import useGetMePlan from '../../hooks/query/useGetMePlan'
import { Me, UserPlan } from '../../service/user'
import { MyPointType } from '../../service/point'
import { useNavigate } from 'react-router-dom'
import useRegistInviteCode from '../../hooks/query/useRegistInviteCode'
import { Modal } from '../layout/Modal'

interface PageProps {
  userPlanInfo: UserPlan | undefined
  myPointData: MyPointType
  handleCopyClipBoard: (copyText: string, successText: string) => Promise<void>
}
export const Page = ({
  children,
  userPlanInfo,
  myPointData,
  handleCopyClipBoard,
}: PropsWithChildren<PageProps>) => {
  const navigate = useNavigate()
  const userInfo: Me = useUserStore((state: any) => state.userInfo)
  const refetch = useUserStore((state: any) => state.refetch)
  const [isSuccess, setSuccess] = useState(false)
  const [isFail, setFail] = useState(false)
  const [isCopySuccess, setCopySuccess] = useState(false)
  const [registInviteCodeValue, setRegistInviteCodeValue] = useState<
    null | string
  >(null)

  const { mutate, isPending } = useRegistInviteCode()
  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text)
    setCopySuccess((prev) => !prev)
  }

  const handleRegistInviteCode = () => {
    if (registInviteCodeValue) {
      mutate(
        { inviteCode: registInviteCodeValue },
        {
          onSuccess: () => {
            setSuccess(true)
          },
          onError: () => {
            setFail(true)
          },
        }
      )
    }
  }

  return (
    <>
      <div
        className="w-[100vw] h-[66px] bg-black flex justify-center items-center flex-col cursor-pointer"
        onClick={() => {
          navigate('/event-page')
        }}
      >
        <img
          src="assets/img/blog_event/blog_to_short.png"
          alt="img"
          className="w-[335px]"
        />
        <button className="font-bold bg-[#FFB3C2] w-[330px] h-[22px] text-[12px] rounded-[40px]">
          블로그 링크만 넣으면, 패스트컷 AI 무료 숏폼 30개 자동 생성!
        </button>
      </div>
      <div className="pt-[60px]  max-w-[816px] md:min-y-[100vh]  my-0 mx-auto flex flex-col px-[16px]">
        <div className="name mb-2 font-bold text-[18px]">
          {userInfo?.userInfo?.name}
          {` `}({userInfo?.userInfo?.email}){` `}님
        </div>
        <div className="flex mb-2">
          <button
            className="flex w-full justify-between bg-main text-white font-bold px-10 py-8 text-[24px] rounded-[40px]"
            onClick={() => {
              navigate('/my-cash-dashboard')
            }}
          >
            <span>보유 캐시</span>
            <span>
              {myPointData.totalPoint.toLocaleString() ?? 0} {`>`}
            </span>
          </button>
        </div>
        <div
          className={classNames(
            'flex flex-col w-full bg-[#12242F] rounded-[40px] px-10 py-8 gap-4',
            {
              'mb-8': userInfo?.registInviteCode && userInfo?.alreadyBenefit,
            }
          )}
        >
          <div className="flex flex-col w-full justify-center items-center gap-2 ">
            <p
              style={{ fontFamily: 'Tenada' }}
              className="text-white  font-[800] text-[24px] text-center leading-[24px]"
            >
              초대코드 런칭 이벤트
              <br />
              11월 한 달 10% 적립
            </p>
            <p style={{}} className="">
              <img src="assets/svg/invite.svg" alt="invite" />
            </p>
            <p className="text-[20px] text-white">
              지금 바로 나의 초대코드를 공유하세요
            </p>
          </div>
          <div className="flex gap-2 w-full mb-4">
            <div className="w-[50%] flex flex-col justify-center items-center text-[14px] gap-1 bg-[#193242] py-3 rounded-[10px]">
              <p className="text-[24px]">🥰</p>
              <p className="text-white font-bold">
                <span className="text-[#F6FE00]">초대 받은 친구는 </span>결제
                금액의
              </p>
              <button className="bg-[#3A8FF2] text-white font-bold px-[10px] py-1 rounded-[40px]">
                {' '}
                5% + 5% 적립
              </button>
            </div>
            <div className="w-[50%] flex flex-col justify-center items-center text-[14px] gap-1 bg-[#193242] py-3 rounded-[10px]">
              <p className="text-[24px]">😎</p>
              <p className="text-white font-bold">
                {' '}
                <span className="text-[#F6FE00]">초대한 나도</span> 친구 결제
                금액의
              </p>
              <button className="bg-[#735CFF] text-white font-bold px-[10px] py-1 rounded-[40px]">
                {' '}
                5% + 5% 적립
              </button>
            </div>
          </div>
          <div className="inviteCode flex flex-col gap-2">
            <div>
              <p className="flex justify-center items-center font-bold text-[24px] gap-4 bg-white h-[53px] rounded-[10px]">
                <span className="text-main">나의 초대 코드</span>{' '}
                <span>{userInfo?.inviteCode ?? '-------'}</span>
              </p>
            </div>
            <div className="flex gap-2">
              <p className="flex justify-center items-center font-bold  gap-1 bg-white h-[53px] rounded-[10px] w-[50%]">
                <button
                  className="flex gap-1 items-center"
                  onClick={() => {
                    handleCopy(userInfo?.inviteCode)
                  }}
                >
                  <img src="assets/svg/copy.svg" alt="copy" />
                  <span>코드 복사하기</span>
                </button>
              </p>
              <p className="flex justify-center items-center font-bold  gap-1 bg-white h-[53px] rounded-[10px] w-[50%]">
                <button
                  className="flex gap-1 items-center"
                  onClick={() => {
                    handleCopy(
                      `${process.env.REACT_APP_URL}?invite_code=${
                        userInfo?.inviteCode ?? ''
                      }`
                    )
                  }}
                >
                  <img src="assets/svg/link.svg" alt="copy" />
                  <span>링크 복사하기</span>
                </button>
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <p className="text-white text-center text-[20px] font-bold">
              더 많은 친구를 초대할수록
              <br /> 캐시 적립 기회 UP UP!
            </p>
          </div>
        </div>

        {!userInfo?.registInviteCode && (
          <div className="no-regist-invite-code bg-[#C4DFFF] flex flex-col px-10 py-8 rounded-[40px] gap-2 mb-8">
            <p className="w-full font-bold text-[20px] text-center">
              첫 결제하고 10% 추가캐시 적립 받으려면!? <br />
              친구의 초대코드를 입력하세요
            </p>
            <div className=" relative">
              <InputAtom
                value={registInviteCodeValue}
                placeholder="초대 코드를 입력하세요"
                className="bg-[#F6F8FB]"
                onChange={(e: any) => {
                  setRegistInviteCodeValue(e.target.value)
                }}
              />

              <div className="flex gap-[16px] absolute right-4 bottom-0 top-0 items-center ">
                <button
                  className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                  onClick={handleRegistInviteCode}
                >
                  적용
                </button>
              </div>
            </div>
          </div>
        )}
        {userInfo?.registInviteCode && !userInfo?.alreadyBenefit && (
          <div className="w-full text-[20px] mb-8">
            <button
              className="text-white font-[800] flex items-center justify-between  w-full rounded-[40px] h-[60px]  px-10"
              style={{
                fontFamily: 'Tenada',
                background:
                  'linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))',
              }}
              onClick={() => {
                navigate('/price-guide')
              }}
            >
              <span className="mt-0.5">
                {' '}
                지금 바로 첫 결제하시면,10% 추가 캐시 적립!
              </span>
              <span className="mt-0.5">{'> '}</span>
            </button>
          </div>
        )}
        <div className="my-videos flex flex-col gap-4 mb-8">
          <p className="flex justify-between items-center w-full">
            <span className="font-bold text-[24px]">최근 일주일 사용 현황</span>
            <button
              className="text-[#5E6068]"
              onClick={() => {
                navigate('/my-videos')
              }}
            >{`상세보기 >`}</button>
          </p>
          <div className=" rounded-[40px] bg-[#F6F8FB] px-10 py-5 flex w-full gap-4">
            <div className="w-[50%] flex flex-col gap-4">
              <p className="font-bold text-[20px]">숏폼</p>
              <div className="flex w-full px-[30px] py-5 bg-white rounded-[20px] justify-evenly">
                <div className="items flex flex-col items-center gap-2">
                  <p
                    className={classNames(
                      'bg-[#C4DFFF] flex items-center justify-center',
                      {
                        'w-[50px] h-[50px]  rounded-[100px] ':
                          userInfo?.fastCutShortUsageSummary[0]?.ready.length ??
                          0 < 3,
                        'w-[70px] h-[50px] rounded-[50px] ':
                          userInfo?.fastCutShortUsageSummary[0]?.ready.length >=
                          3,
                      }
                    )}
                  >
                    <span className="text-white font-bold text-[24px]">
                      {userInfo?.fastCutShortUsageSummary[0]?.ready ?? 0}
                    </span>
                  </p>
                  <p className="font-bold">생성 대기</p>
                </div>
                <div className="items flex flex-col items-center gap-2">
                  <p
                    className={classNames(
                      'bg-[#3A8FF2]  flex items-center justify-center',
                      {
                        'w-[50px] h-[50px]  rounded-[100px] ':
                          userInfo?.fastCutShortUsageSummary[0]?.complete
                            .length ?? 0 < 3,
                        'w-[70px] h-[50px] rounded-[50px] ':
                          userInfo?.fastCutShortUsageSummary[0]?.complete
                            .length >= 3,
                      }
                    )}
                  >
                    <span className="text-white font-bold text-[24px]">
                      {' '}
                      {userInfo?.fastCutShortUsageSummary[0]?.complete ?? 0}
                    </span>
                  </p>
                  <p className="font-bold">생성 완료</p>
                </div>
              </div>
            </div>
            <div className="w-[50%] flex flex-col gap-4">
              <p className="font-bold text-[20px]">롱폼</p>
              <div className="flex w-full px-[30px] py-5 bg-white rounded-[20px] justify-evenly">
                <div className="items flex flex-col items-center gap-2">
                  <p
                    className={classNames(
                      'bg-[#C4DFFF]  flex items-center justify-center',
                      {
                        'w-[50px] h-[50px]   rounded-[100px]':
                          userInfo?.fastCutUsageSummary[0]?.ready.length ??
                          0 < 3,
                        'w-[70px] h-[50px]  rounded-[50px] ':
                          userInfo?.fastCutUsageSummary[0]?.ready.length >= 3,
                      }
                    )}
                  >
                    <span className="text-white font-bold text-[24px]">
                      {userInfo?.fastCutUsageSummary[0]?.ready ?? 0}
                    </span>
                  </p>
                  <p className="font-bold">생성 대기</p>
                </div>
                <div className="items flex flex-col items-center gap-2">
                  <p
                    className={classNames(
                      'bg-[#3A8FF2]  flex items-center justify-center',
                      {
                        'w-[50px] h-[50px]   rounded-[100px]':
                          userInfo?.fastCutUsageSummary[0]?.complete.length ??
                          0 < 3,
                        'w-[70px] h-[50px]  rounded-[50px] ':
                          userInfo?.fastCutUsageSummary[0]?.complete.length >=
                          3,
                      }
                    )}
                  >
                    <span className="text-white font-bold text-[24px]">
                      {' '}
                      {userInfo?.fastCutUsageSummary[0]?.complete ?? 0}
                    </span>
                  </p>
                  <p className="font-bold">생성 완료</p>
                </div>
                <div className="items flex flex-col items-center gap-2">
                  <p
                    className={classNames(
                      'bg-[#735CFF]  w-[50px] h-[50px] rounded-[100px] flex items-center justify-center',
                      {
                        'w-[50px] h-[50px]   rounded-[100px]':
                          userInfo?.fastCutUsageSummary[0]?.upload.length ??
                          0 < 3,
                        'w-[70px] h-[50px]  rounded-[50px] ':
                          userInfo?.fastCutUsageSummary[0]?.upload.length >= 3,
                      }
                    )}
                  >
                    <span className="text-white font-bold text-[24px]">
                      {userInfo?.fastCutUsageSummary[0]?.upload ?? 0}
                    </span>
                  </p>
                  <p className="font-bold">등록 완료</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-videos flex flex-col gap-4 mb-8">
          <p className="flex justify-between items-center w-full">
            <span className="font-bold text-[24px]">결제 플랜 관리</span>
            <button
              className="text-[#5E6068]"
              onClick={() => {
                navigate('/my-payments')
              }}
            >{`상세보기 >`}</button>
          </p>
          <div className=" rounded-[40px] bg-[#F6F8FB] flex flex-col text-[18px] gap-[18px] px-10 py-8">
            <div className="plan flex">
              <p className="mr-[16px] font-bold">플랜</p>

              <p className="mr-[16px]">
                <span
                  className={classNames('font-bold', {
                    'text-href': userInfo?.userType === UserType.체험,
                    'text-main': userInfo?.userType !== UserType.체험,
                  })}
                >
                  {userPlanInfo?.subscriptions?.planName
                    ? userPlanInfo?.subscriptions?.planName
                    : '아직 사용중인 플랜이 없어요!'}
                </span>{' '}
                {userPlanInfo?.subscriptions &&
                userPlanInfo?.planPrice?.planPrice[0].subscriptionType &&
                userPlanInfo?.planPrice?.planPrice[0].subscriptionType ===
                  'monthly'
                  ? userInfo?.userType !== UserType.체험 && '1개월'
                  : ''}
                {userPlanInfo?.subscriptions &&
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType &&
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType ===
                    'annual' &&
                  '1년'}
              </p>
            </div>
            {userInfo?.userType !== UserType.체험 && (
              <div className="date flex">
                <p className="mr-[16px] font-bold">기간</p>
                {userPlanInfo?.subscriptions?.currentMonth && (
                  <p className="text-main font-bold mr-[16px] text-[18px]">
                    {userPlanInfo?.subscriptions?.currentMonth}
                  </p>
                )}
                <p className="">
                  {userPlanInfo?.subscriptions &&
                    userPlanInfo?.subscriptions.subscriptionType ===
                      'monthly' &&
                    userPlanInfo?.subscriptions?.startDate &&
                    `${userPlanInfo?.subscriptions.startDate} ~ ${userPlanInfo?.subscriptions.endDate}`}

                  {userPlanInfo?.subscriptions &&
                    userPlanInfo?.subscriptions.subscriptionType === 'annual' &&
                    userPlanInfo?.subscriptions?.monthStart &&
                    `${userPlanInfo?.subscriptions.monthStart} ~ ${userPlanInfo?.subscriptions.monthEnd}`}

                  {!userPlanInfo?.subscriptions && '-- ~ --'}
                </p>
              </div>
            )}
            <div className="status flex">
              <p className="mr-[16px] font-bold">현황</p>
              <p className="mr-[16px]">
                <span
                  className={classNames('font-bold', {
                    'text-href': userInfo?.userType === UserType.체험,
                    'text-main': userInfo?.userType !== UserType.체험,
                  })}
                >
                  요청{' '}
                  {userPlanInfo?.usage?.usedCount
                    ? userPlanInfo?.usage?.usedCount
                    : 0}
                  개
                </span>{' '}
                / 최대{' '}
                {userPlanInfo?.usage?.totalLimit
                  ? userPlanInfo?.usage?.totalLimit
                  : 0}
                개
              </p>
            </div>
          </div>
        </div>
        {isSuccess && (
          <SuccessModal
            isSuccess={isSuccess}
            setSuccess={setSuccess}
            refetch={refetch}
          />
        )}

        {isFail && <FailModal isFail={isFail} setFail={setFail} />}
        {isCopySuccess && (
          <CopyModal
            isCopySuccess={isCopySuccess}
            setCopySuccess={setCopySuccess}
          />
        )}
      </div>
    </>
  )
}

const InputAtom = (props: any) => {
  const { error, inputRef, isCheck, userCheck, onChange } = props

  const inputClass = classNames(
    'w-[343px] text-base md:w-full h-[70px]  rounded-[10px] p-[16px] bg-[#F6F8FB]',
    {
      'border border-error focus:outline-none': error,
      'border-[#D9DEE8]': !error,
      'opacity-[0.5]': isCheck,
    }
  )
  return (
    <input
      {...props}
      className={inputClass}
      ref={inputRef}
      onClick={userCheck}
      onChange={onChange}
    />
  )
}

const SuccessModal = (props: any) => {
  const { isSuccess, setSuccess, navigate, refetch } = props
  return (
    <Modal isModal={isSuccess}>
      <div className="bg-white w-[400px] h-[208px] p-[20px] border-t rounded-t-[40px]">
        <div className="flex flex-col items-center justify-center h-full gap-4">
          <p className="font-bold text-[18px]">💁🏻 초대 코드 적용 완료!</p>
          <div className="flex flex-col leading-[22px]">
            <p className=" text-[14px] ">
              패스트컷 플랜 결제시
              <br />
              결제금의 10%가 포인트로 적립됩니다.
            </p>
          </div>
        </div>
      </div>
      <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
        <button
          className="text-white w-[100%] h-full bg-main rounded-b-[40px] border-b border-main font-bold"
          onClick={() => {
            refetch && refetch()
            setSuccess(false)
          }}
        >
          확인
        </button>
      </div>
    </Modal>
  )
}
const FailModal = (props: any) => {
  const { isFail, setFail } = props
  return (
    <Modal isModal={isFail}>
      <div className="bg-white w-[400px] h-[208px] p-[20px] border-t rounded-t-[40px]">
        <div className="flex flex-col items-center justify-center h-full gap-4">
          <p className="font-bold text-[18px]">💁🏻 초대 코드 오류</p>
          <div className="flex flex-col leading-[22px]">
            <p className=" text-[14px] ">
              입력하신 초대 코드는 존재하지 않는 코드입니다.
              <br />
              코드를 다시 잘 확인해서 입력해주세요!
            </p>
          </div>
        </div>
      </div>
      <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
        <button
          className="text-white w-[100%] h-full bg-main rounded-b-[40px] border-b border-main font-bold"
          onClick={() => {
            setFail(false)
          }}
        >
          확인
        </button>
      </div>
    </Modal>
  )
}
const CopyModal = (props: any) => {
  const { isCopySuccess, setCopySuccess } = props
  return (
    <Modal isModal={isCopySuccess}>
      <div className="bg-white w-[300px] h-[128px] p-[20px] border-t rounded-t-[40px]">
        <div className="flex flex-col items-center justify-center h-full gap-4">
          <p className=" text-[18px] font-bold">✅ 복사 완료!</p>
        </div>
      </div>
      <div className="w-[300px] text-[14px]  h-[60px]  flex justify-center items-center">
        <button
          className="text-white w-[100%] h-full bg-main rounded-b-[40px] border-b border-main font-bold"
          onClick={() => {
            setCopySuccess(false)
          }}
        >
          확인
        </button>
      </div>
    </Modal>
  )
}
