import { useLocation, useNavigate } from 'react-router-dom'
import useUserStore from '../../store/useUserStore'
import { useEffect, useState } from 'react'
import { useLogOut } from '../../hooks/query/useLogOut'
import { isMobile } from 'react-device-detect'
import useGetMe from '../../hooks/query/useGetMe'

import {
  barList,
  getLocalStorage,
  removeLocalStorage,
  saveLocalStorage,
  UserType,
} from '../../utils/common'
import { FreeButton } from '../atom/FreeButton'
import usePrevStore from '../../store/usePrevStore'
const Header = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const userType = urlParams.get('userType')
  const navigate = useNavigate()
  const sample = getLocalStorage('guide_sample')
  if (!sample) {
    saveLocalStorage('guide_sample', '1')
  }
  const location = useLocation()
  if (token) {
    localStorage.setItem('token', token)
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('token')
    const baseUrl = location.pathname
    const modifiedUrl = `${baseUrl}${
      searchParams.toString() ? '?' + searchParams.toString() : ''
    }`

    navigate(modifiedUrl)
  }

  const { pathname } = useLocation()
  const [logOutStatus, setLogOutStatus] = useState<boolean>(false)
  const [hambugerStatus, setHambugerStatus] = useState<boolean>(false)

  const { mutate: logoutMutate } = useLogOut()
  const set = useUserStore((state: any) => state.set)
  const prevSet = usePrevStore((state: any) => state.set)
  const setRefetch = useUserStore((state: any) => state.setRefetch)
  const { data: userData, refetch } = useGetMe()

  set({ userInfo: userData })

  const moveToPageByPath = (path: string) => {
    setHambugerStatus(false)
    switch (path) {
      case 'sign-in':
        navigate('sign-in')
        break
      case 'sign-up':
        navigate('sign-up')
        break
      case 'video-editor':
        navigate('/video-editor')
        break
      case 'youtube-auth':
        navigate('/youtube-auth')
        break
      case 'my-payments':
        navigate('/my-payments')
        break
      case 'my-videos':
        navigate('/my-videos')
        break
      case 'price-guide':
        navigate('/price-guide')
        break
      case 'faq':
        navigate('/QnA')
        break
      case 'short-video-editor':
        navigate('/short-video-editor')
        break
      case 'my-cash-dashboard':
        navigate('/my-cash-dashboard')
        break
      case 'event-page':
        navigate('/event-page')
        break
      case 'my-page':
        navigate('/my-page')
        break
      default:
        navigate('/')
        break
    }
  }
  const handleHambugerIconClick = () => {
    setHambugerStatus(!hambugerStatus)
  }
  const onLogOut = () => {
    removeLocalStorage('videoEditorData')
    localStorage.removeItem('token')
    // remove('access_token', { path: '/' })
    logoutMutate('', {
      onSuccess: () => {},
      onError: (e) => {
        console.log(e)
      },
    })
    setTimeout(() => {
      navigate('/')
      window.location.reload()
    }, 1000)
  }

  const createMobileBtn = () => {
    return (
      <>
        <div
          className="flex justify-center mb-[18px] mt-[30px]"
          onClick={handleHambugerIconClick}
        >
          <button>
            <img src="assets/img/Icon_x.png" alt="x" width={32} height={32} />
          </button>
        </div>
        <div className="flex flex-col items-center px-[26px] py-[8px]">
          {!userData && (
            <button
              className="py-[16px] w-full"
              onClick={() => {
                moveToPageByPath('short-video-editor')
              }}
            >
              숏폼 만들기
            </button>
          )}
          {!userData && (
            <button
              className="py-[16px] w-full"
              onClick={() => {
                moveToPageByPath('video-editor')
              }}
            >
              롱폼 만들기
            </button>
          )}

          <button
            className={`py-[16px] w-full  ${
              pathname === '/price-guide' && 'text-main'
            }`}
            onClick={() => {
              moveToPageByPath('price-guide')
            }}
          >
            가격 안내
          </button>

          <button
            className=" py-[16px] w-full "
            onClick={() => {
              moveToPageByPath('faq')
            }}
          >
            자주 묻는 질문
          </button>

          <button
            className=" py-[16px] w-full flex items-center  justify-center"
            onClick={() => {
              moveToPageByPath('event-page')
            }}
          >
            <p>이벤트</p>
            <p className="bg-[#5AC367] text-white p-1 font-bold text-[10px] rounded-[4px] ml-1 h-[20px] flex items-center">
              <span>blog</span>
            </p>
          </button>

          {/* <button className=" py-[16px] w-full ">
            <a
              href="https://solution.fastseller.shop"
              target="_blank"
              rel="noreferrer"
              className="w-full block"
            >
              패스트셀러
            </a>
          </button> */}

          <div className="h-[1px] w-full bg-[gray] mt-[16px] "></div>

          {userData?.userInfo ? (
            <div className="flex flex-col gap-[8px] mt-[16px] w-full items-center">
              <button
                className="py-[16px] w-full  text-center"
                onClick={() => {
                  moveToPageByPath('my-page')
                }}
              >
                마이페이지{' '}
                <span className="text-[#2E343D]  font-bold">{`(${userData.userInfo.name})`}</span>
              </button>
              <button
                className="py-[16px] w-full"
                onClick={() => {
                  moveToPageByPath('short-video-editor')
                }}
              >
                숏폼 만들기
              </button>
              <button
                className="py-[16px] w-full"
                onClick={() => {
                  moveToPageByPath('video-editor')
                }}
              >
                롱폼 만들기
              </button>

              <button
                className="py-[16px] w-full"
                onClick={() => {
                  moveToPageByPath('my-videos')
                }}
              >
                사용 현황
              </button>
              <button
                className="py-[16px] w-full"
                onClick={() => {
                  moveToPageByPath('my-cash-dashboard')
                }}
              >
                캐시 관리
              </button>
              <button
                className="py-[16px] w-full"
                onClick={() => {
                  moveToPageByPath('my-payments')
                }}
              >
                결제 플랜 관리
              </button>
              {(userData.userType === UserType.프로 ||
                userData.userType === UserType.프리미엄 ||
                userData.userType === UserType.체험) && (
                <div className="relative py-[16px] ">
                  <button
                    onClick={() => {
                      moveToPageByPath('youtube-auth')
                    }}
                  >
                    자동 업로드 설정
                  </button>
                  {/* 
                  <p className="flex flex-col justify-center items-start gap-[2px] absolute -right-7 top-5  text-[6px] text-[#FFFFFF] bg-main font-bold rounded-[2px] p-[1px] w-[24px] h-[16px] leading-[4px]">
                    <span>PRO</span>
                    <span>ULTRA</span>
                  </p> */}
                </div>
              )}
              <button className="py-[16px] w-full" onClick={onLogOut}>
                로그 아웃
              </button>
            </div>
          ) : (
            // 로그인 안되어 있을 때 버튼 리스트
            <div className="flex flex-col gap-[16px] mt-[32px] w-full items-center">
              <button
                className="text-[#2E343D] py-[13px] w-full"
                onClick={() => {
                  moveToPageByPath('sign-in')
                }}
              >
                로그인
              </button>
              <FreeButton
                className=" p-[20px] h-[48px] bg-href  text-white font-bold rounded-[30px] flex justify-center items-center"
                onClick={() => {
                  moveToPageByPath('sign-up')
                }}
              >
                가입하고 무료체험
              </FreeButton>
            </div>
          )}
        </div>
      </>
    )
    // 공통 요소
  }

  const createSelectBtn = () => {
    //우상단 선택 요소들
    return (
      <>
        <div
          className="flex flex-col bg-[#FFFFFF] w-[169px] h-auto justify-center items-center absolute right-[30px] border rounded-[10px] font-bold top-20 z-50"
          onMouseLeave={() => setLogOutStatus(false)}
        >
          <div className="hover:bg-[#F6F6F6] w-full h-[46px]  flex items-center justify-center">
            <button
              onClick={() => {
                moveToPageByPath('my-page')
              }}
              className="w-full h-full"
            >
              마이페이지
            </button>
          </div>
          <div className="hover:bg-[#F6F6F6] w-full h-[46px]  flex items-center justify-center">
            <button
              onClick={() => {
                moveToPageByPath('short-video-editor')
              }}
              className="w-full h-full"
            >
              숏폼 만들기
            </button>
          </div>
          <div className="hover:bg-[#F6F6F6] w-full h-[46px]  flex items-center justify-center">
            <button
              onClick={() => {
                moveToPageByPath('video-editor')
              }}
              className="w-full h-full"
            >
              롱폼 만들기
            </button>
          </div>
          <div className="hover:bg-[#F6F6F6] w-full h-[46px]  flex items-center justify-center">
            <button
              onClick={() => {
                moveToPageByPath('my-cash-dashboard')
              }}
              className="w-full h-full"
            >
              캐시 관리
            </button>
          </div>
          <div className="hover:bg-[#F6F6F6] w-full h-[46px] flex items-center justify-center">
            <button
              onClick={() => {
                moveToPageByPath('my-videos')
              }}
              className="w-full h-full"
            >
              사용 현황
            </button>
          </div>

          <div className="hover:bg-[#F6F6F6] w-full h-[46px]  flex items-center justify-center">
            <button
              onClick={() => {
                moveToPageByPath('my-payments')
              }}
              className="w-full h-full"
            >
              결제 플랜 관리
            </button>
          </div>
          {userData &&
            (userData.userType === UserType.프로 ||
              userData.userType === UserType.프리미엄 ||
              userData.userType === UserType.체험) && (
              <div className="relative hover:bg-[#F6F6F6] w-full h-[46px] flex items-center justify-center">
                <button
                  onClick={() => {
                    moveToPageByPath('youtube-auth')
                  }}
                  className="w-full h-full"
                >
                  자동 업로드 설정
                </button>
              </div>
            )}

          <div className="hover:bg-[#F6F6F6] w-full h-[46px]  flex items-center justify-center">
            <button onClick={onLogOut} className="w-full h-full">
              로그아웃
            </button>
          </div>
        </div>
      </>
    )
  }

  const createButtonEl = () => {
    if (userData?.userInfo && userData?.userInfo) {
      const { name } = userData.userInfo

      return (
        <>
          <div
            className="font-bold md:py-[13px] px-[2px] md:px-[20px] flex items-center gap-2 relative"
            onMouseEnter={() => {
              setLogOutStatus(true)
            }}
          >
            <p className="text-[8px] md:text-base hover:text-[#768296] ">{`MY (${name})`}</p>
            <img
              src={
                logOutStatus
                  ? 'assets/img/icon_arrow.png'
                  : 'assets/img/icon_arrow2.png'
              }
              alt="arrow"
              className="w-[12.8px] h-[8.25px]"
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="flex ">
            <button
              className="font-bold py-[13px] px-[17px] md:px-[34px] text-[10px] md:text-base hover:text-[#768296]"
              onClick={() => {
                moveToPageByPath('sign-in')
              }}
            >
              로그인
            </button>
            <FreeButton
              className="bg-href  text-white font-bold  py-[6px] md:py-[13px] px-[17px] md:px-[34px] rounded-[30px] md:rounded-[60px] text-[10px] md:text-base"
              onClick={() => {
                moveToPageByPath('sign-up')
              }}
            >
              가입하고 무료체험
            </FreeButton>
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    if (refetch && typeof refetch === 'function') {
      console.log('Refetch function detected:', refetch) // refetch 확인
      setRefetch(refetch) // refetch가 함수일 때만 설정
    } else {
      console.log('Refetch not ready:', refetch) // 초기 상태 확인
    }
  }, [refetch, setRefetch])

  useEffect(() => {
    if (hambugerStatus) {
      // scroll 금지'
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [hambugerStatus])
  useEffect(() => {
    if (
      pathname !== '/sign-in' &&
      pathname !== '/sign-up' &&
      pathname !== '/terms'
    ) {
      console.log(pathname)
      prevSet({ prevUrl: pathname })
    }

    if (userData?.userInfo && !userData?.userInfo.termsAccepted) {
      const userType = urlParams.get('userType')
      navigate(`/terms?userType=${userType}`)
    }
  }, [pathname])

  return (
    <div className="border-b border-[#D9DEE8] border-gray w-[100%]">
      <header
        className="flex items-center justify-between max-w-[1232px] h-[80px] md:h-[100px] px-3 md:px-4 my-0 mx-auto relative"
        onMouseLeave={() => setLogOutStatus(false)}
      >
        {logOutStatus && createSelectBtn()}
        {hambugerStatus && (
          <div className="fixed bg-[#FFFFFF] border-t w-full h-[100vh] left-0 top-0 z-[999] overflow-y-auto pb-[200px]">
            {createMobileBtn()}
          </div>
        )}

        <div className="flex items-center  ">
          <button className="mr-8">
            <h1
              onClick={() => {
                navigate('/')
              }}
            >
              <img
                className="w-[100px] md:w-[150px] y-[27px]"
                src="/assets/img/logo.png"
                alt="logo"
              />
            </h1>
          </button>
          {!isMobile && (
            <div className="flex">
              {barList.map((el) => {
                return (
                  <button
                    className={`flex  items-center font-bold md:py-[13px] px-[5px]  md:px-[24px] text-[8px] md:text-base hover:text-[#768296]  ${
                      pathname === `/${el.path}` && 'text-main'
                    }`}
                    onClick={() => {
                      moveToPageByPath(el.path)
                    }}
                  >
                    {el.name}
                    {el.event && (
                      <p className="bg-[#5AC367] text-white p-1 font-bold text-[10px] rounded-[4px] ml-1 h-[20px] flex items-center">
                        <span>blog</span>
                      </p>
                    )}
                  </button>
                )
              })}
            </div>
          )}
        </div>
        {isMobile ? (
          <>
            <button onClick={handleHambugerIconClick}>
              <img
                src="/assets/img/Icon_hambuger.png"
                alt="hambuger"
                width={32}
              />
            </button>
          </>
        ) : (
          createButtonEl()
        )}
      </header>
    </div>
  )
}

export default Header
