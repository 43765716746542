import API from './api'

export const subscriptionType = {
  MONTHLY: 'monthly',
  SEMIANNUAL: 'semiannual',
  ANNUAL: 'annual',
} as const

export interface PlanDetailType {
  type: string
  name: string
  highlight: boolean
}

export interface Plan {
  id: number
  name: string
  paidType?: string
  planDetail: PlanDetailType[]
}

export interface PriceType {
  id: number
  name: string
  planPrice: SubscriptionPlan[]
}
export interface SubscriptionType {
  prices: PriceType[]
  benefitType?: string
  benefitInfo?: {
    affiliateDescription?: string
    affiliateImage?: string
    daysLeft?: string
    hoursLeft?: string
    minutesLeft?: string
    secondsLeft?: string
    inviteCode?: string
    bonusRate?: number
  }
}

export interface SubscriptionPlan {
  id: number
  subscriptionType: (typeof subscriptionType)[keyof typeof subscriptionType]
  originPrice: number
  price: number
  description: string
  descriptionPrice: number
  subscriptionTypeLabel: string
  monthlyPrice: number
  bonusPoint?: number
}
export async function getPlans(): Promise<Plan[]> {
  try {
    return await API.get('/plans', {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getFreePlan(): Promise<Plan> {
  try {
    return await API.get('/plans/free', {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getPlansPrice(
  subscriptionType: string
): Promise<SubscriptionType> {
  try {
    return await API.get(`/plans/v2/price/${subscriptionType}`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}
