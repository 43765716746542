import React, { useEffect, useState } from 'react'
import { My } from '../components/My'
import { ShadowBox } from '../components/ShadowBox'
import { isMobile } from 'react-device-detect'
import { useLocation, useNavigate } from 'react-router-dom'
import useGetMePlan from '../hooks/query/useGetMePlan'

import { FastCut, UsageRequest, UsageRequestFactor } from '../service/list'
import useGetUsageRequest from '../hooks/query/useGetUsageRequest'

import useUserStore from '../store/useUserStore'

import { showErrorBox, UserType } from '../utils/common'
import { GuideToUse } from '../components/GuideToUse'
import classNames from 'classnames'
import { AfterGuideCircle } from '../components/freePlan/GuideCircle'
import { PromotionCircle } from '../components/upgrade/PromotionCircle'
import useGetShortUsageRequest from '../hooks/query/useGetShortUsageRequest'

export const MyVideos = () => {
  const FAST_CUT = 'fastcut'
  const FAST_SHORT = 'fastshort'

  const location = useLocation()
  const navigate = useNavigate()
  const [totalItems, setTotalItems] = useState(0)
  const itemsPerPage = 80
  const [defaultPage, setDefaultPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const params = new URLSearchParams(location.search)
  const tType = params.get('type')
  let page = parseInt(params.get('page') || '', 10)

  const [tab, setTab] = useState(
    tType && tType === 'cut' ? FAST_CUT : FAST_SHORT
  )
  if (!page || isNaN(page) || page < 1) {
    page = defaultPage
    params.set('type', tab === FAST_CUT ? 'cut' : 'short')
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }
  //* 패스트컷 비디오 사용량
  const {
    data: usageRequest,
    refetch: usageRefetch,
    isError,
  } = useGetUsageRequest(page, itemsPerPage)

  //* 패스트숏츠 비디오 사용량
  const {
    data: shortUsageRequest,
    refetch: shortUsageRefetch,
    isError: isShortError,
  } = useGetShortUsageRequest(page, itemsPerPage)

  const { data: userPlanInfo, refetch, isLoading } = useGetMePlan()

  // user의 영상생성 개수 불러오기
  const userInfo = useUserStore((state: any) => state.userInfo)

  const [usageList, setUsageList] = useState<UsageRequestFactor[] | []>([])

  const createTable = () => {
    if (isMobile) {
      return (
        <>
          <table className="min-w-full border-collapse">
            <thead className="">
              <tr className="border-b text-center text-[#5E6068]">
                <th className="w-[30px] px-2 pb-5">NO</th>
                <th className="px-2 pb-5 text-left">
                  {tab === FAST_CUT ? '영상' : '숏폼'} 제목
                </th>
                <th className=" w-[79px] px-1 pb-5">상태</th>
              </tr>
            </thead>
            <tbody>
              {usageList.length > 0 ? (
                <>
                  {usageList.map((el, index) => {
                    const {
                      mainTitle,
                      createdAt: requestedAt,
                      contentCreatedAt,
                      uploadedAt,
                      driveLink,
                      status,
                    } = el

                    return (
                      <>
                        <tr className="text-center text-[#5E6068]">
                          <td
                            className={`px-1  align-top ${
                              index === 0 ? 'py-[24px]' : 'pb-[24px]'
                            }`}
                          >
                            {totalItems &&
                              (totalItems - itemsPerPage * (page - 1) - index)
                                .toString()
                                .padStart(2, '0')}
                          </td>
                          <td
                            className={`px-1  align-top text-left ${
                              index === 0 ? 'py-[24px]' : 'pb-[24px]'
                            }`}
                          >
                            {mainTitle !== ''
                              ? mainTitle
                              : '영상 생성 준비중...'}
                          </td>
                          <td
                            className={` flex items-start h-[24px] px-1  align-top text-left ${
                              index === 0 ? 'py-[24px]' : 'pb-[24px]'
                            }`}
                          >
                            <a
                              href={driveLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <button
                                className={`text-[#FFFFFF] font-bold rounded-[20px] text-[12px] w-[79px] h-[24px] flex justify-center items-center gap-[4px]  ${
                                  driveLink && ' active:scale-95'
                                } ${status === '생성 대기' && 'bg-[#C4DFFF]'} ${
                                  status === '생성 완료' && 'bg-[#3A8FF2]'
                                }  ${
                                  status === '등록 완료' && 'bg-[#464DEE]'
                                } `}
                              >
                                <span>{status}</span>{' '}
                                <img
                                  src="assets/img/icon_cloud.png"
                                  alt="cloud"
                                  height={14}
                                  width={14}
                                />
                              </button>
                            </a>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </>
              ) : (
                <>
                  {' '}
                  <tr>
                    <td
                      colSpan={6}
                      rowSpan={10}
                      className="text-center py-[80px]"
                    >
                      <p className="text-[18px] font-bold leading-[28px]">
                        아직 생성 요청하신 영상이 없어요!
                        <br />
                        어필리에이트 링크와 정보를 입력해서 영상을 만들어보세요!
                        <br />
                        <span className="text-main">
                          <button
                            onClick={() => {
                              tab === FAST_CUT
                                ? navigate('/video-editor')
                                : navigate('/short-video-editor  ')
                            }}
                          >
                            {tab === FAST_CUT
                              ? '롱폼 만들러 가기 >>'
                              : '숏폼 만들러 가기 >>'}
                          </button>
                        </span>
                      </p>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <div className="pagenation flex justify-center gap-[4px] ">
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
              onClick={() => handlePageEndChange(1)}
              disabled={currentPage === 1}
            >
              <img src="assets/img/icon_arrow_left_end.png" alt="icon" />
            </button>
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src="assets/img/icon_arrow_left.png" alt="icon" />
            </button>
            {renderPageNumbers()}
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src="assets/img/icon_arrow_right.png" alt="icon" />
            </button>
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
              disabled={currentPage === totalPages}
              onClick={() => {
                handlePageEndChange(totalPages)
              }}
            >
              <img src="assets/img/icon_arrow_right_end.png" alt="icon" />
            </button>
          </div>
        </>
      )
    } else {
      return (
        <>
          <ShadowBox>
            <table className="min-w-full border-collapse">
              <thead className="">
                <tr className="border-b text-center text-[#5E6068]">
                  <th className="w-[30px] px-2 pb-5">NO</th>
                  <th className="px-2 pb-5 text-left">
                    {tab === FAST_CUT ? '영상' : '숏폼'} 제목
                  </th>
                  <th className=" w-[42px] px-1 pb-5">요청</th>
                  <th className=" w-[42px] px-1 pb-5">생성</th>
                  {tab === FAST_CUT && (
                    <th className=" w-[42px] px-1 pb-5">등록</th>
                  )}

                  <th className=" w-[79px] px-1 pb-5">상태</th>
                </tr>
              </thead>
              <tbody className="">
                {usageList?.length > 0 ? (
                  <>
                    {usageList.map((el, index) => {
                      const {
                        mainTitle,
                        createdAt: requestedAt,
                        uploadedAt,
                        contentCreatedAt,
                        driveLink,
                        status,
                      } = el

                      return (
                        <>
                          <tr className="text-center text-[#5E6068]">
                            <td className="px-1  p-[12px]">
                              {totalItems &&
                                (totalItems - itemsPerPage * (page - 1) - index)
                                  .toString()
                                  .padStart(2, '0')}
                            </td>
                            <td className="px-1  p-[12px] text-left">
                              {mainTitle !== ''
                                ? mainTitle
                                : '영상 생성 준비중...'}
                            </td>
                            <td className="request px-1 p-[12px]">
                              {requestedAt}
                            </td>
                            <td className="create px-1 p-[12px]">
                              {contentCreatedAt}
                            </td>
                            {tab === FAST_CUT && (
                              <td className="upload px-1   p-[12px]">
                                {uploadedAt}
                              </td>
                            )}
                            <td className="h-[24px] px-1  p-[12px]">
                              <a
                                href={driveLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <button
                                  className={`flex items-center justify-center gap-[4px] text-[#FFFFFF] font-bold rounded-[20px] text-[12px] w-[79px] h-[24px]
                                  ${driveLink && ' active:scale-95'} ${
                                    status === '생성 대기' && 'bg-[#C4DFFF]'
                                  } ${
                                    status === '생성 완료' && 'bg-[#3A8FF2]'
                                  }  ${
                                    status === '등록 완료' && 'bg-[#464DEE]'
                                  } `}
                                >
                                  <span>{status}</span>{' '}
                                  {driveLink && (
                                    <img
                                      src="assets/img/icon_cloud.png"
                                      alt="cloud"
                                      height={14}
                                      width={14}
                                    />
                                  )}
                                </button>
                              </a>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </>
                ) : (
                  <>
                    {' '}
                    <tr>
                      <td
                        colSpan={6}
                        rowSpan={10}
                        className="text-center py-[80px]"
                      >
                        <p className="text-[18px] font-bold leading-[28px]">
                          아직 생성 요청하신{' '}
                          {tab === FAST_CUT ? '영상' : '숏폼'}
                          이 없어요!
                          <br />
                          {tab === FAST_CUT
                            ? '어필리에이트 링크와 정보를 입력해서 영상을 만들어보세요!'
                            : '네이버 뉴스 링크를 입력해서 숏폼을 만들어보세요!'}
                          <br />
                          <span className="text-main">
                            <button
                              onClick={() => {
                                tab === FAST_CUT
                                  ? navigate('/video-editor')
                                  : navigate('/short-video-editor  ')
                              }}
                            >
                              {tab === FAST_CUT
                                ? '롱폼 만들러 가기 >>'
                                : '숏폼 만들러 가기 >>'}
                            </button>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
              {/* <div className="w-full">
               
              </div> */}
            </table>
            <div className=" pagenation flex justify-center gap-[4px] ">
              <button
                className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
                onClick={() => handlePageEndChange(1)}
                disabled={currentPage === 1}
              >
                <img src="assets/img/icon_arrow_left_end.png" alt="icon" />
              </button>
              <button
                className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <img src="assets/img/icon_arrow_left.png" alt="icon" />
              </button>
              {renderPageNumbers()}
              <button
                className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <img src="assets/img/icon_arrow_right.png" alt="icon" />
              </button>
              <button
                className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
                disabled={currentPage === totalPages}
                onClick={() => {
                  handlePageEndChange(totalPages)
                }}
              >
                <img src="assets/img/icon_arrow_right_end.png" alt="icon" />
              </button>
            </div>
          </ShadowBox>
        </>
      )
    }
  }

  const renderPageNumbers = () => {
    const pagesPerBlock = 10
    const pageNumbers = []
    const currentBlock = Math.floor((currentPage - 1) / pagesPerBlock)
    const startPage = currentBlock * pagesPerBlock + 1
    const endPage = Math.min(startPage + pagesPerBlock - 1, totalPages)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          className={classNames(
            `flex items-center justify-center w-[32px] h-[32px] border-[1px]  font-bold text-[14px]`,
            {
              'border-main text-main': currentPage === i,
              'border-[#DFE3E8]': currentPage !== i,
            }
          )}
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={currentPage === i}
        >
          {i}
        </button>
      )
    }

    return pageNumbers
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    const params = new URLSearchParams(location.search)
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }
  const handlePageEndChange = (page: number) => {
    setCurrentPage(page)
    const params = new URLSearchParams(location.search)
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }

  const handleTab = (type: string) => {
    setTab(type)
  }

  const createLIEl = () => {
    if (tab === FAST_CUT) {
      return (
        <>
          {' '}
          <li className="p-[4px] text-href font-bold underline">
            <a
              href="https://angelleague.notion.site/0d23bb71864942dca2bbf89c60e4a15b?pvs=4"
              target="_blank"
              rel="noreferrer"
            >
              라이트플랜 영상 업로드 방법 가이드
            </a>
          </li>
          <li className="p-[4px]">
            영상 다운로드는 생성 완료된 시점으로부터 30일간 가능합니다.
          </li>
          <li className="p-[4px]">
            프로, 프리미엄 플랜의 경우, 유튜브 권한 설정이 완료된 상태여야
            유튜브 자동 업로드가 가능합니다.
          </li>
          <li className="p-[4px]">
            플랜별 영상 생성 개수는 사용자가 ‘생성 요청한 영상’의 개수를
            기준으로 카운팅 합니다.
          </li>
          <li className="p-[4px]">영상 생성은 요청 후 3일 내에 완료됩니다.</li>
          <li className="p-[4px]">
            프로,프리미엄 플랜은 1일 최대 3개까지 오전 10시, 오후 2시, 오후
            6시에 업로드 예약 됩니다
          </li>
          <li className="p-[4px]">
            생성과 업로드 일정 등은 서비스 현황에 따라 별도의 공지 없이 변동될
            수 있습니다.
          </li>
        </>
      )
    } else if (tab === FAST_SHORT) {
      return (
        <>
          <li className="p-[4px] text-href font-bold underline">
            <a
              href="https://angelleague.notion.site/6abfba9240964bd88fd4b7981850de3a?pvs=4"
              target="_blank"
              rel="noreferrer"
            >
              숏폼 업로드 방법 가이드
            </a>
          </li>
          <li className="p-[4px]">
            숏폼 파일 다운로드는 생성 완료된 시점으로부터 30일간 가능합니다.
          </li>
          <li className="p-[4px]">영상 생성은 요청 후 3일 내에 완료됩니다.</li>
          <li className="p-[4px]">
            생성 일정 등은 서비스 현황에 따라 별도의 공지 없이 변동될 수
            있습니다.
          </li>
        </>
      )
    }
  }

  useEffect(() => {
    if (usageRequest && tab === FAST_CUT) {
      setUsageList(usageRequest.data)
      setTotalItems(usageRequest.totalCount)
    }
    if (isShortError && tab === FAST_SHORT) {
      setUsageList([])
      setTotalItems(0)
    }
    if (shortUsageRequest && tab === FAST_SHORT) {
      setUsageList(shortUsageRequest.data)
      setTotalItems(shortUsageRequest.totalCount)
    }
    if (isError && tab === FAST_CUT) {
      setUsageList([])
      setTotalItems(0)
    }
  }, [usageRequest, shortUsageRequest, tab])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const page = parseInt(params.get('page') || '', 10)
    if (page && tab === FAST_CUT) {
      setCurrentPage(page)
      usageRefetch()
    } else if (page && tab === FAST_SHORT) {
      setCurrentPage(page)
      shortUsageRefetch()
    }
  }, [location.search])

  useEffect(() => {
    let page = 1
    params.set('type', tab === FAST_CUT ? 'cut' : 'short')
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }, [tab])
  return (
    <div className="md:pt-[60px] pt-[24px] md:w-[816px] w-[375px] min-h-[100vh] my-0 mx-auto flex flex-col px-[16px]">
      <div className="md:w-full w-[343px] ">
        <My>
          <p>사용 현황</p>
        </My>
        <div className="flex justify-center ">
          <p className="font-bold text-[24px] md:text-[48px]">사용 현황</p>
        </div>
        <div className="flex gap-4 justify-center mt-[24px]">
          <button
            className={classNames(
              'px-[44px] py-[12px] font-bold text-[24px]  rounded-[40px]',
              {
                'text-white  bg-main': tab === FAST_SHORT,
                'text-[#F6F8FB]  bg-[#B6BBC6]': tab === FAST_CUT,
              }
            )}
            onClick={() => {
              handleTab(FAST_SHORT)
            }}
          >
            숏폼
          </button>
          <button
            className={classNames(
              'px-[44px] py-[12px] font-bold text-[24px]  rounded-[40px]',
              {
                'text-white  bg-main': tab === FAST_CUT,
                'text-[#F6F8FB]  bg-[#B6BBC6]': tab === FAST_SHORT,
              }
            )}
            onClick={() => {
              handleTab(FAST_CUT)
            }}
          >
            롱폼
          </button>
        </div>
        {tab === FAST_CUT && (
          <>
            {' '}
            {showErrorBox(userInfo)}
            <PromotionCircle
              userInfo={userInfo}
              planId={userInfo?.subscriptionType?.planId}
            />
            <div
              className={classNames(
                ' bg-[#F6F8FB] w-full rounded-[40px] flex flex-col text-[18px] gap-[18px] mt-[24px] px-[24px] py-[32px]',
                {
                  'border-[2px] border-href':
                    userInfo?.userType === UserType.체험,
                }
              )}
            >
              <div className="plan flex">
                <p className="mr-[16px] font-bold">플랜</p>

                <p className="mr-[16px]">
                  <span
                    className={classNames('font-bold', {
                      'text-href': userInfo?.userType === UserType.체험,
                      'text-main': userInfo?.userType !== UserType.체험,
                    })}
                  >
                    {userPlanInfo?.subscriptions?.planName
                      ? userPlanInfo?.subscriptions?.planName
                      : '아직 사용중인 플랜이 없어요!'}
                  </span>{' '}
                  {userPlanInfo?.subscriptions &&
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType &&
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType ===
                    'monthly'
                    ? userInfo?.userType !== UserType.체험 && '1개월'
                    : ''}
                  {userPlanInfo?.subscriptions &&
                    userPlanInfo?.planPrice?.planPrice[0].subscriptionType &&
                    userPlanInfo?.planPrice?.planPrice[0].subscriptionType ===
                      'annual' &&
                    '1년'}
                </p>
              </div>
              {userInfo?.userType !== UserType.체험 && (
                <div className="date flex">
                  <p className="mr-[16px] font-bold">기간</p>
                  {userPlanInfo?.subscriptions?.currentMonth && (
                    <p className="text-main font-bold mr-[16px] text-[18px]">
                      {userPlanInfo?.subscriptions?.currentMonth}
                    </p>
                  )}
                  <p className="">
                    {userPlanInfo?.subscriptions &&
                      userPlanInfo?.subscriptions.subscriptionType ===
                        'monthly' &&
                      userPlanInfo?.subscriptions?.startDate &&
                      `${userPlanInfo?.subscriptions.startDate} ~ ${userPlanInfo?.subscriptions.endDate}`}

                    {userPlanInfo?.subscriptions &&
                      userPlanInfo?.subscriptions.subscriptionType ===
                        'annual' &&
                      userPlanInfo?.subscriptions?.monthStart &&
                      `${userPlanInfo?.subscriptions.monthStart} ~ ${userPlanInfo?.subscriptions.monthEnd}`}

                    {!userPlanInfo?.subscriptions && '-- ~ --'}
                  </p>
                </div>
              )}

              <div className="status flex">
                <p className="mr-[16px] font-bold">현황</p>
                <p className="mr-[16px]">
                  <span
                    className={classNames('font-bold', {
                      'text-href': userInfo?.userType === UserType.체험,
                      'text-main': userInfo?.userType !== UserType.체험,
                    })}
                  >
                    요청{' '}
                    {userPlanInfo?.usage?.usedCount
                      ? userPlanInfo?.usage?.usedCount
                      : 0}
                    개
                  </span>{' '}
                  / 최대{' '}
                  {userPlanInfo?.usage?.totalLimit
                    ? userPlanInfo?.usage?.totalLimit
                    : 0}
                  개
                </p>
              </div>
            </div>
            {userInfo?.userType === UserType.체험 && (
              <>
                <div className="mt-[40px]">
                  {' '}
                  <AfterGuideCircle />{' '}
                </div>
              </>
            )}
          </>
        )}

        {
          <>
            <div className="list mt-[40px]">{createTable()}</div>
            <div className="mb-[40px]">
              {' '}
              <ul className="list-disc pl-8 text-left mt-4"></ul>
            </div>
          </>
        }
        {createLIEl()}
        <div className="flex justify-center">
          <GuideToUse />
        </div>
      </div>
    </div>
  )
}
