import { subscriptionType } from './plans'
import API from './api'

export interface PaymentOrderRequest {
  orderId: string
  productName: string
  subscriptionType: string
  price: number
  vat: number
  id: number
}

export interface InviteBenefit {
  bonusPoint: number
}
export interface AffiliateBenefit {
  originalPrice: number
  discountRate: number
  discountAmount: number
  discountedPrice: number
}
export interface Affiliate {
  plan: AffiliatePlan[]
  point: AffiliatePoint
}

export interface AffiliatePlan {
  affiliateCode: string
  affiliateDescription: string
  affiliateImage: string
  daysLeft: string
  daysValid: number
  description: string
  hoursLeft: string
  minutesLeft: string
  name: string
  planDiscountAmount: string
  planDiscountRate: string
  planId: number
  planSubscriptionType: string
  point: string
  pointExpired: string
  pointExpiredDay: string
  pointRate: string
  pointReasonType: string
  secondsLeft: string
  type: string
  validUntil: string
}

export interface AffiliatePoint {
  affiliateCode: string
  affiliateDescription: string
  affiliateImage: string
  daysLeft: string
  daysValid: number
  description: string
  hoursLeft: string
  minutesLeft: string
  name: string
  planDiscountAmount: string
  planDiscountRate: string
  planId: string
  planSubscriptionType: string
  point: string
  pointExpired: string
  pointExpiredDay: number
  pointRate: string
  pointReasonType: string
  secondsLeft: string
  type: string
  validUntil: string
}
export interface ConfirmPaymentParams {
  paymentKey: string
  orderId: string
  amount: number
}

export interface getPlanParams {
  planId: number
  planPriceId: number
}

export interface DiscountType {
  originalPrice: number
  discountRate: number
  discountAmount: number
  discountedPrice: number
}
export async function getPaymentOrderRequests({
  planId,
  planPriceId,
}: getPlanParams) {
  try {
    const data: {
      orderRequest: PaymentOrderRequest
      affiliateBenefit?: AffiliateBenefit
      inviteBenefit?: InviteBenefit
    } = await API.get(`/payment/v2/${planId}/${planPriceId}`, {})
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function confirmPayment({
  paymentKey,
  orderId,
  amount,
}: ConfirmPaymentParams) {
  try {
    const data = await API.post('/payment/confirm', {
      paymentKey,
      orderId,
      amount,
    })
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function postExperiencePlan(data: any) {
  try {
    await API.post('/payment/experience-plan', {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function discountCodeCheck({
  invitationCode,
  planId,
  subscriptionType,
}: any): Promise<DiscountType> {
  try {
    return await API.get('/invitation/apply-discount', {
      params: {
        invitationCode,
        planId,
        subscriptionType,
      },
    })
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function updatePaymentOrderRequest({
  id,
  discountValue,
  discountCode,
  upgrade,
  monthPrice,
}: any) {
  try {
    return await API.put(`/payment/${id}`, {
      discountValue,
      discountCode,
      upgrade,
      monthPrice,
    })
  } catch (error) {
    // 오류 처리
    throw error
  }
}
