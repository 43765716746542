import React from 'react'
import { useNavigate } from 'react-router-dom'

export const BeforeGuideCircle = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-href  text-white  md:w-full w-[335px] h-[70px] rounded-[40px] flex md:flex-row md:justify-start flex-col items-center px-[40px] justify-center font-bold md:text-[18px] text-[13px]">
      <p className="font-bold">
        <button className="w-full">
          프리미엄 플랜 1회 무료체험권을 선물해드렸어요! 지금 바로 영상을
          만들어보세요!
        </button>
      </p>
    </div>
  )
}

export const AfterGuideCircle = () => {
  const navigate = useNavigate()
  return (
    <div
      className="bg-[#2E343D]  text-white  w-full h-[70px] rounded-[40px] flex md:flex-row md:justify-start flex-col items-center px-[40px]  md:text-[18px] justify-center font-bold text-[14px] cursor-pointer"
      onClick={() => {
        navigate('/price-guide')
      }}
    >
      <p className="font-bold">
        <button className="w-full">
          {`무료 체험 사용 완료! 잘 사용 하셨나요? 영상을 더 만들어보시려면!! >> `}
        </button>
      </p>
    </div>
  )
}
